* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

$dark-blue: #070e1b;
$light-blue: #0a101e;
$yellow: #ffc107;
$light-yellow: #ffc107f6;
$text-color: #a9adb8;

.text-color {
  color: $text-color;
}

.bg_light_blue {
  background-color: $light-blue;
}

.bg_dark_blue {
  background-color: $dark-blue;
}

.header {
  background-color: $dark-blue;
  color: #ffc107;

  li {
    transition: all 0.4s ease;
    color: white;

    &:hover {
      color: $yellow;
    }
  }
}

.home_main {
  background-image: url("./img/hero-bg\ \(1\).jpg");
  height: 120vh;
  max-width: 100%;

  @media (max-width: 600px) {
    height: auto;
    width: 100%;
    overflow-x: hidden;
  }

  @media (min-width: 1400px) {
    height: 800px;
  }

  .home_content {
    align-items: center;
    height: 120vh;
    display: flex;
    overflow: hidden;

    @media (max-width: 768px) {
      height: 65vh;
    }

    @media (min-width: 1400px) {
      height: 800px;
      width: 1200px ;
      padding-left: 0px;
      padding-right: 0px;
      margin: auto;
    }

    .content {
      h1 {
        color: white;
      }

      h2 {
        color: silver;
      }

      h4 {
        color: $yellow;
      }
    }

    .home_img {
      height: auto;
      width: 500px;
      text-align: center;
      position: relative;

      .homeImg {
        position: absolute;
        left: -20px;
      }
    }
  }
}

.footer {
  background-color: $light-blue;
}

.animate__animated.animate__jello {
  transition-delay: 0.2s;
  animation-duration: 2s;
}

.btn {
  background-color: $light-yellow;
  color: #070d1b;

  &:hover {
    box-shadow: 0px 2px 5px 0px $yellow;
    background-color: $yellow;
    transition-delay: 0.2s;
    transition: all 0.4s ease;
  }
}

.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}

.banner img {
  animation: updown 3s linear infinite;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  margin: auto;

  @media (max-width: 768px) {
    width: 280px;
    height: 280px;
  }
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.btn_hire_me {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: Roboto, sans-serif;
  line-height: 1.5em;
  font-weight: 500;
  padding: 10px 1.5em;
  border-radius: 50px;
  min-width: 170px;
  border: none;
  outline: none;
  text-transform: capitalize;
}

.about {
  background-color: $light-blue;

  .heading {
    text-align: center;
    position: relative;
    margin-top: -5px;
    padding-bottom: 41px;
  }

  .main_heading {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    color: $yellow;
    position: relative;
    z-index: 10;
    letter-spacing: 1px;

    &::before {
      content: "";
      position: absolute;
      background-color: #3f4551;
      width: 170px;
      height: 2px;
      bottom: -12px;
      left: 50%;
      transform: translate(-50%);
    }

    &::after {
      content: "";
      position: absolute;
      background-color: $yellow;
      width: 70px;
      height: 2px;
      bottom: -12px;
      left: 50%;
      transform: translate(-50%);
    }
  }

  .sub_heading {
    text-transform: uppercase;
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translate(-50%);
    font-size: 90px;
    font-weight: 700;
    color: #151b2980;
    margin: -17px 0 0;
    width: 100%;
  }
}

.heading {
  text-align: center;
  position: relative;
  margin-top: -5px;
  padding-bottom: 41px;
}

.main_heading {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: $yellow;
  position: relative;
  z-index: 10;
  letter-spacing: 1px;

  &::before {
    content: "";
    position: absolute;
    background-color: #3f4551;
    width: 170px;
    height: 2px;
    bottom: -12px;
    left: 50%;
    transform: translate(-50%);
  }

  &::after {
    content: "";
    position: absolute;
    background-color: $yellow;
    width: 70px;
    height: 2px;
    bottom: -12px;
    left: 50%;
    transform: translate(-50%);
  }
}

.sub_heading {
  text-transform: uppercase;
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translate(-50%);
  font-size: 90px;
  font-weight: 700;
  color: #151b2980;
  margin: -17px 0 0;
  width: 100%;
}

.about_content {
  h2 {
    color: white;
  }

  h3 {
    color: $yellow;
  }

  p {
    color: $text-color;
    line-height: 1.75;
  }

  .info {
    // font-size: 18px;
  }
}

.services {
  .box {
    width: 340px;
    height: auto;
    padding: 50px 30px;
    text-align: center;
    cursor: pointer;
    border-radius: 12px;
    transition: all 0.4s ease;
    border: 1px solid #232935;
    background-color: #101624;
    color: white;
    margin: 12px;

    &:hover {
      i {
        color: $yellow;
      }

      .bold {
        color: $yellow;
      }

      .box_icon {
        border-color: $yellow;
      }
    }

    .box_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90px;
      width: 90px;
      border: 0.5px solid #3f4551;
      border-radius: 50%;
      background-color: #070d1b;
      margin: auto auto 28px;
      font-size: 50px;
      transition: all 0.4s ease;

      i {
        font-size: 80%;
        transition: all 0.4s ease;
      }
    }

    .bold {
      transition: all 0.4s ease;
      font-weight: 400;
      line-height: 1.2em;
      font-size: 20px;
      padding-bottom: 10px !important;
    }

    .simple {
      color: $text-color;
      font-size: 14px;
      margin-bottom: -6px;
    }
  }
}

.skills {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    display: none !important;
  }

  .box {
    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 6px;
    transition: all 0.4s ease;
    border: 2px solid #232935;
    background-color: #070d1b;
    color: white;
    margin: 12px;
    box-shadow: 0px 0px 10px 0px rgb(238, 234, 234);
    transition-delay: 0.2s;
    transition: all 0.4s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      // padding-bottom: 10px !important;
    }

    b {
      transition: all 0.4s ease;
      font-weight: 400;
      line-height: 1.2em;
      font-size: 18px;
      display: none;
      // padding-bottom: 10px !important;
    }

    button {
      border: 1px solid #232935;
      border-radius: 6px;
      width: 100%;
      background-color: #070d1b;
      transition-delay: 0.2s;
      transition: all 0.4s ease;
      padding: 5px;
    }
  }
}

.html {
  // &:hover {
  border-color: #e65100 !important;
  transition: all 0.4s ease;
  // box-shadow: 0px 0px 15px 0px white;
  transition-delay: 0.2s;

  button {
    background-color: #e65100;
    transition-delay: 0.2s;
    border-color: #e65100;
  }

  // }

  @media (max-width: 500px) {
    border-color: #e65100 !important;
    transition: all 0.4s ease;

    button {
      background-color: #e65100 !important;
      transition-delay: 0.2s;
      border-color: #e65100 !important;
    }
  }
}

.css {
  // &:hover {
  border-color: #0277bd !important;
  transition: all 0.4s ease;
  // box-shadow: 0px 0px 15px 0px white;
  transition-delay: 0.2s;

  button {
    background-color: #0277bd;
    transition-delay: 0.2s;
    border-color: #0277bd;
  }

  // }
}

.javascript {
  // &:hover {
  border-color: #f7df1e !important;
  transition: all 0.4s ease;
  // box-shadow: 0px 0px 15px 0px white;
  transition-delay: 0.2s;

  button {
    background-color: #f7df1e;
    transition-delay: 0.2s;
    border-color: #f7df1e;
  }

  // }
}

.react {
  // &:hover {
  border-color: #00d8ff !important;
  transition: all 0.4s ease;
  // box-shadow: 0px 0px 15px 0px white;
  transition-delay: 0.2s;

  button {
    background-color: #00d8ff;
    transition-delay: 0.2s;
    border-color: #00d8ff;
  }

  // }
}

.redux {
  // &:hover {
  border-color: #6c4eb0 !important;
  transition: all 0.4s ease;
  // box-shadow: 0px 0px 15px 0px white;
  transition-delay: 0.2s;

  button {
    background-color: #6c4eb0;
    transition-delay: 0.2s;
    border-color: #6c4eb0;
  }

  // }
}

.bootstrap {
  // &:hover {
  border-color: #6c19ff !important;
  transition: all 0.4s ease;
  // box-shadow: 0px 0px 15px 0px white;
  transition-delay: 0.2s;

  button {
    background-color: #6c19ff;
    transition-delay: 0.2s;
    border-color: #6c19ff;
  }

  // }
}

.tailwind {
  // &:hover {
  border-color: #00acc1 !important;
  transition: all 0.4s ease;
  // box-shadow: 0px 0px 15px 0px white;
  transition-delay: 0.2s;

  button {
    background-color: #00acc1;
    transition-delay: 0.2s;
    border-color: #00acc1;
  }

  // }
}

.mui_color {
  border-color: #0288d1 !important;
}

.mui {
  // &:hover {
  transition: all 0.4s ease;
  // box-shadow: 0px 0px 15px 0px white;
  transition-delay: 0.2s;

  button {
    background-color: #0288d1;
    transition-delay: 0.2s;
    border-color: #0288d1;
  }

  // }
}

.content {
  .left_content {
    width: 45vw;
    padding: 12px;
    padding-left: 0px;

    @media (max-width: 800px) {
      width: 100%;
    }

    @media (min-width: 1400px) {
      width: 60% ;
      padding-left: 0px;
      padding-right: 0px;
      margin: auto;
    }

    h1 {
      font-size: 30px;
      font-weight: 400;
      margin-top: -6px;
      margin-bottom: 22px;

      @media (max-width: 600px) {
        font-size: 25px;
      }
    }

    .input {
      display: block;
      width: 100%;
      color: #a9adb8;
      padding: 12px 20px;
      border: 1px solid #232935;
      background-color: #101624;
      height: 52px;
      border-radius: 7px;
      transition: all 0.4s ease;
      margin-bottom: 30px;
    }

    textarea {
      height: 150px !important;
    }
  }

  .right_content {
    padding: 12px;

    @media (max-width: 600px) {
      padding-top: 24px;
    }
    
    // @media (max-width: 350px) {
    //   padding-top: 24px;
    //   width: 300px;
    //   padding-left: 0px;
    //   padding-right: 0px;
    //   flex-wrap: wrap;
    // }

    
    @media (min-width: 1400px) {
      width: 400px ;
      padding-left: 0px;
      padding-right: 0px;
      margin: auto;
    }

    h1 {
      font-size: 30px;
      font-weight: 400;
      margin-top: -6px;

      @media (max-width: 600px) {
        font-size: 25px;
      }
    }

    p {
      color: $text-color;
    }

    b {
      font-size: 20px;
      font-weight: 400;
      // margin-top: -4px;
      margin-bottom: 6px;

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }

    .single_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.5px solid #3f4551;
      border-radius: 50%;
      background-color: #070d1b;
      width: 50px;
      height: 50px;
      margin-right: 20px;
      transition: all 0.4s ease;
      color: $text-color;

      &:hover {
        border-color: $light-yellow;
      }
    }

    .social {
      display: flex;
      border: 1px solid #3f4551;
      border-radius: 50px;
      margin-right: 15px;
      transition: all 0.4s ease; // Smooth transition for the container

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        font-size: 20px;
        text-align: center;
        color: $text-color;
        transition: transform 0.4s ease; // Smooth scaling transition for icon
      }

      span {
        transition: opacity 0.4s ease, transform 0.4s ease; // Smooth fade and slide for span
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        display: none;
      }

      &:hover {
        padding-right: 18px; // Padding change on hover

        i {
          transform: scale(1.1); // Slightly scale up the icon
        }

        span {
          display: flex;
          opacity: 1; // Fade in the span
          transform: translateX(0); // Ensure smooth slide-in effect
          transition-delay: 0.2s; // Delay to make the span appear after icon scaling
        }
      }
    }

  }
}

.resume {
  i {
    color: #3c434e;
    font-size: 35px;

    @media (max-width: 600px) {
      font-size: 30px;
    }
  }

  h1 {
    margin: 0 0 0 30px;
    font-size: 36px;
    font-weight: 400;

    @media (max-width: 600px) {
      font-size: 31px;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 400;
    color: $yellow;
    margin-top: -11px;
    margin-bottom: 10px;

    @media (max-width: 600px) {
      font-size: 19px;
    }
  }

  h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #a9adb8;

    @media (max-width: 600px) {
      font-size: 13px;
    }
  }

  b {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px !important;
    color: white;

    @media (max-width: 600px) {
      font-size: 15px;
    }
  }

  p {
    color: #a9adb8;
  }

  .heading {
    padding-bottom: 50px;
  }

  .degree {
    padding-top: 1px;
    padding-bottom: 50px;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      border: 2px solid #232935;
      border-radius: 50%;
      background-color: #101624;
      width: 13px;
      height: 13px;
      top: 0px;
      left: -47px;
      transition: all 0.4s ease;

      &:hover {
        background-color: $yellow;
      }
    }
  }
}

.degree1 {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    border: 2px solid #232935;
    border-radius: 50%;
    background-color: #101624;
    width: 13px;
    height: 13px;
    top: 11px;
    left: -47px;
    transition: all 0.4s ease;

    &:hover {
      background-color: $yellow;
    }
  }
}

.side {
  padding-left: 40px;
  margin-left: 20px;
  border-left: 1px solid #232935;
}

.portfolio {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;

  .box_hover {
    position: absolute;
    width: 320px;
    height: 230px;
    background-color: #10162463;
    display: none;
    top: -1px;
    left: -1px;
    border: #070d1b;
    // display: flex;
    justify-content: center;
    align-items: center;

   

    a {
      width: 70px;
      height: 70px;
      border: 1px solid black;
      border-radius: 50%;
      display: none;
      justify-content: center;
      align-items: center;
      margin: 10px;

      &:hover {
        color: $yellow !important;
        border: 2px solid $yellow;
      }

      color: black;

      i {
        font-size: 30px;
      }
    }
  }

  .box {
    width: 320px;
    height: 230px;
    margin: 12px;
    text-decoration: none;
    // border: 1px solid white;
    border-radius: 10px !important;
    display: flex;
    justify-content: space-around;

    img{
      border-radius: 6px;
    }

    @media (max-width: 800px) {
      margin: 12px auto;
    }

    &:hover {
      .box_hover {
        display: flex;

        a {
          display: flex;
        }
      }
    }
  }
}

.sidebar {
  background-color: $light-blue;
  color: white;

  &:hover {
    background-color: $dark-blue;
  }

  .side_btn {
    padding: 10px;
    padding-left: 20px;
    display: flex;
    align-items: center;

    &:hover {
      color: $yellow;
      background-color: $dark-blue;
    }

    span {
      width: 30px;
      display: flex;
      justify-content: center;

      margin-right: 15px;
    }
  }
}

.active {
  color: $yellow !important;
}


.largeScreen {
  max-width: 1090px !important;
  margin: auto !important;
}

.cursor {
  position: fixed;
  width: 15px;
  height: 15px;
  background-color: #F6BA08;
  border-radius: 50%;
  pointer-events: none;
}


/* Webkit Browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #F6BA08;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners on the thumb */
  border: 3px solid #f1f1f1;
  /* Padding around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #3b2d7d;
  /* Thumb color on hover */
}

/* Firefox */
* {
  scrollbar-width: thin;
  /* Set scrollbar width */
  scrollbar-color: #F6BA08 #1E2230;
  /* Color of the thumb and track */
}

/* Optional: Style specific scrollable elements */
.scrollable-element {
  overflow-y: scroll;
  /* Enable vertical scrolling */
}

.scrollable-element::-webkit-scrollbar {
  width: 8px;
}

.scrollable-element::-webkit-scrollbar-thumb {
  background-color: #F6BA08;
}

.scrollable-element::-webkit-scrollbar-thumb:hover {
  background-color: #3b2d7d;
}


.cardContainer {
  height: 110vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0px;

  .card {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 400px;
    width: 100%;
    border-radius: 25px;
    padding: 50px;
    transform-origin: top;
    margin-top: 0px;
    box-shadow: rgba(50, 50, 93, 0.25) 5px 2px 15px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    .body {
      display: flex;
      height: 100%;
      // margin-top: 50px;
      gap: 50px;
      
      .description {
        width: 50%  ;
        position: relative;
        top: 10%;
        // display: none;

        p {
          font-size: 16px;

          &::first-letter {
            font-size: 28px;
            font-family: 'Title';
          }
        }

        span {
          display: flex;
          align-items: center;
          gap: 5px;

          a {
            font-size: 12px;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .imageContainer {
        position: relative;
        width: fit-content;
        height: 100%;
        border-radius: 25px;
        overflow: hidden;

        .inner {
          width: 100%;
          height: 100%;
        }

        img {
          object-fit: cover;
        }
      }
    }
  }
}